import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../fetch'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Dropdown from '../../UI/Dropdown/Dropdown'
import './ModalContent.scss'

const Start = ({ close }) => {
  const [selectedProject, setSelectedProject] = useState(null)
  const [projectList, setProjectList] = useState(null)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)

  useEffect(() => {
    const getProjects = async () => {
      const { status, projects } = await fetch(METHOD.GET, '/project/list', { token })
      if (status && projects) {
        setProjectList(projects)
      }
    }

    if (token && !projectList) getProjects()
  }, [token, projectList])

  const start = async () => {
    if (selectedProject) {
      const { status } = await fetch(METHOD.POST, '/time', { token, projectUUID: selectedProject.uuid, timezoneOffsetInMinutes: new Date().getTimezoneOffset() })
      if (status) {
        close()
      }
    }
  }

  const update = (key) => setSelectedProject(projectList.find((p) => p.uuid === key))

  if (!projectList) return <></>

  return (
    <div id="modalContent">
      <Dropdown
        showEmptyOption={false}
        name={translation['project.select']}
        options={projectList.map((p) => ({ key: p.uuid, value: p.name }))}
        onChange={update}
        value={selectedProject ? selectedProject.uuid : ''}
      />
      <div className="btnAction" onClick={start}>
        <div>{translation['button.start']}</div>
        <PlayArrowIcon />
      </div>
    </div>
  )
}

export default Start
