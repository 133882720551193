import { getPrettyDateTime } from '../../../../utility.js'
import { StyledEntry, State, Headline, PropWrapper, Key, Value, Owner, Editer, Header, StateWrapper, StateHeadline } from './TimeEntry.Styled.js'

const TimeEntry = ({ entry }) => {
  const allowedProperties = ['startTime', 'breakTimeInMinutes', 'startTime', 'endTime', 'tasks']

  const dateKeys = ['startTime', 'endTime']

  return (
    <StyledEntry>
      <Header>
        <Headline>Project: {entry.project?.name}</Headline>
        <Owner>
          Owner: {entry?.ownerUser?.firstname} {entry?.ownerUser?.lastname}
        </Owner>
        <Editer>
          Editer: {entry?.ownerUser?.firstname} {entry?.ownerUser?.lastname}
        </Editer>
      </Header>
      <StateWrapper>
        <State>
          <StateHeadline>Previous State</StateHeadline>
          {Object.keys(entry.previousState)
            .filter((key) => allowedProperties.includes(key))
            .map((key) => (
              <PropWrapper>
                <Key>{key}:</Key>
                <Value>{dateKeys.includes(key) ? getPrettyDateTime(new Date(entry.previousState[key])) : entry.previousState[key]}</Value>
              </PropWrapper>
            ))}
        </State>
        <State>
          <StateHeadline>New State</StateHeadline>
          {Object.keys(entry.newState)
            .filter((key) => allowedProperties.includes(key))
            .map((key) => (
              <PropWrapper>
                <Key>{key}</Key>
                <Value>{dateKeys.includes(key) ? getPrettyDateTime(new Date(entry.newState[key])) : entry.newState[key]}</Value>
              </PropWrapper>
            ))}
        </State>
      </StateWrapper>
    </StyledEntry>
  )
}

export default TimeEntry
