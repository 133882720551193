import { useState } from 'react'
import Input from '../../../UI/Input/Input'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import Button from '../../../UI/Button/Button'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../../fetch'
import './ModalContent.scss'

const ModalContent = ({ user, modalContentType, setModalContentType }) => {
  const [newUser, setNewUser] = useState(user || {})
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)

  const updateValue = (key) => (value) => {
    setNewUser({ ...newUser, [key]: value })
  }

  const upsert = async (e) => {
    e.preventDefault()
    const { status } = await fetch(user ? METHOD.PUT : METHOD.POST, '/admin/user', { token, user: newUser })
    if (status) {
      setNewUser({})
      setModalContentType(null)
    }
  }

  const deleteUser = async () => {
    const { status } = await fetch(METHOD.DELETE, '/admin/user', { token, userUUID: user.uuid })
    if (status) {
      setNewUser({})
      setModalContentType(null)
    }
  }

  return (
    <div id="modalBody">
      <form onSubmit={upsert}>
        <Input required value={newUser.firstname || ''} onChange={updateValue('firstname')} placeholder={translation['admin.user.firstname']} />
        <Input required value={newUser.lastname || ''} onChange={updateValue('lastname')} placeholder={translation['admin.user.lastname']} />
        <Input required value={newUser.mail || ''} onChange={updateValue('mail')} placeholder={translation['admin.user.mail']} />
        <Input required={!user} value={newUser.password || ''} onChange={updateValue('password')} placeholder={translation['admin.user.password']} />
        <Checkbox text={translation['admin.user.isAdmin']} checked={newUser.isAdmin || false} onChange={updateValue('isAdmin')} />
        <Button type="submit" text={modalContentType === 'create' ? translation['admin.user.create-user'] : translation['admin.user.update-user']} />
        {modalContentType === 'edit' && <Button onClick={deleteUser} text={translation['admin.user.delete-user']} />}
      </form>
    </div>
  )
}

export default ModalContent
