import styled from 'styled-components'

export const Headline = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
`

export const ListWrapper = styled.div`
  margin-top: 50px;
`
