import { useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import { useSelector } from 'react-redux'
import Layout from '../../Layout/Layout'
import Modal from '../../UI/Modal/Modal'
import List from './List/List'
import ModalContent from './ModalContent/ModalContent'
import Button from '../../UI/Button/Button'
import './UserManagement.scss'

const UserManagement = () => {
  const [modalContentType, setModalContentType] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)

  const translation = useSelector((state) => state.translation)

  const handleClose = () => {
    setModalContentType(null)
    setSelectedUser(null)
  }

  return (
    <Layout authRequired={'admin'}>
      <Container>
        <div className="user-management">
          <div className="headline">{translation['admin.user-management.list.headline']}</div>
          <Button text={translation['admin.user.create-user']} onClick={() => setModalContentType('create')} />
          <List modalContentType={modalContentType} setModalContentType={setModalContentType} setSelectedUser={setSelectedUser} />
          <Modal open={!!modalContentType} close={handleClose} title={selectedUser ? selectedUser.mail : translation['admin.user.create-user']}>
            {modalContentType && <ModalContent user={selectedUser} modalContentType={modalContentType} setModalContentType={setModalContentType} />}
          </Modal>
        </div>
      </Container>
    </Layout>
  )
}

export default UserManagement
