import { Route, Routes } from 'react-router-dom'

import ProjectManagement from './ProjectManagement/ProjectManagement'
import UserManagement from './UserManagement/UserManagement'
import Export from './Export/Export.js'
import EditHistory from './EditHistory/EditHistory.js'

const AdminRouter = () => {
  return (
    <Routes>
      <Route path="project-management" element={<ProjectManagement />} />
      <Route path="user-management" element={<UserManagement />} />
      <Route path="edit-history" element={<EditHistory />} />
      <Route path="export" element={<Export />} />
    </Routes>
  )
}

export default AdminRouter
