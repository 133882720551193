import styled from 'styled-components'

export const StyledEntry = styled.div`
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
`

export const Header = styled.div``

export const State = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 50%;

  @media (max-width: 992px) {
    width: 100%;
  }
`

export const Headline = styled.div`
  color: white;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
`

export const StateWrapper = styled.div`
  display: flex;
  gap: 50px;
  width: 100%;
  justify-content: center;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`

export const PropWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  /* margin: 5px 0; */
  color: white;
`

export const Key = styled.div`
  width: 50%;
`

export const Value = styled.div`
  width: 50%;
`

export const Owner = styled.div`
  color: white;
`

export const Editer = styled.div`
  color: white;
  margin-bottom: 10px;
`

export const StateHeadline = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
`
