import { useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../../fetch'
import toast, { TYPE } from '../../../../toast'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import Modal from '../../../UI/Modal/Modal'
import Members from './Members/Members'
import './Upsert.scss'

const Create = ({ show, close, projectCallback, project }) => {
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)
  const [name, setName] = useState(project && project.name ? project.name : '')
  const [members, setMembers] = useState(project && project.members ? project.members : [])

  const submit = async (e) => {
    e.preventDefault()
    const { status, project: upsertedProject } = await fetch(project ? METHOD.PUT : METHOD.POST, '/admin/project', { token, name, members, projectUUID: project && project.uuid ? project.uuid : null })
    if (status && upsertedProject) {
      projectCallback(upsertedProject)
      setName('')
      setMembers([])
      close()
    } else {
      toast(TYPE.ERROR, translation['admin.project-management.upsert.error'])
    }
  }

  const completeProject = async () => {
    const { status, project: upsertedProject } = await fetch(METHOD.PUT, '/admin/project', { token, name, members, projectUUID: project.uuid, completed: true })
    if (status && upsertedProject) {
      projectCallback(upsertedProject)
    } else {
      toast(TYPE.ERROR, translation['admin.project-management.upsert.error'])
    }
  }

  return (
    <Modal open={show} close={close} title={translation['admin.project-management.upsert.headline']}>
      <div className="project-upsert">
        <form onSubmit={submit}>
          <Input disabled={project && project.completed ? true : false} type="text" value={name} onChange={setName} placeholder={translation['admin.project-management.upsert.name.placeholder']} />
          <Members selectedCallback={setMembers} members={members} disabled={project && project.completed} />
          {(!project || !project.completed) && <Button type="submit" text={translation['admin.project-management.upsert.submit-cta']} fullWidth={false} />}
          {project && !project.completed && (
            <Button className="complete-button" type="button" onClick={completeProject} text={translation['admin.project-management.upsert.mark-as-complete-cta']} fullWidth={false} />
          )}
        </form>
      </div>
    </Modal>
  )
}

export default Create
