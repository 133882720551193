import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './components/Auth/Login/Login'
import Home from './components/Home/Home'
import AdminRouter from './components/Admin/AdminRouter'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/*" element={<AdminRouter />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  )
}

export default App
