import { backendDomain } from './utility'
import axios from 'axios'

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

const fetch = async (method, uriSuffix, body = {}, overwriteURL = false) => {
  try {
    if (!uriSuffix) return false
    const result = await axios({
      method,
      url: overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`,
      [method === METHOD.GET ? 'params' : 'data']: body,
    })
    return result.data
  } catch (err) {
    return false
  }
}

export const fileDownload = async (uriSuffix, body = {}, overwriteURL = false) => {
  const result = await axios.post(overwriteURL ? uriSuffix : `${backendDomain}${uriSuffix}`, body, {
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  })
  return result.data
}

export default fetch
