import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../../fetch'
import usePrevious from '../../../Helper/usePrevious'
import './List.scss'

const List = ({ modalContentType, setModalContentType, setSelectedUser }) => {
  const token = useSelector((state) => state.token)
  const [userList, setUserList] = useState(null)
  const prevContentType = usePrevious(modalContentType)

  useEffect(() => {
    const getUsers = async () => {
      const { status, users } = await fetch(METHOD.GET, '/admin/user/list', { token })
      if (status && users) {
        setUserList(users)
      }
    }

    if (token && (!userList || modalContentType !== prevContentType)) getUsers()
  }, [token, userList, modalContentType, prevContentType])

  const onClickUser = (user) => () => {
    setSelectedUser(user)
    setModalContentType('edit')
  }

  return (
    <div id="userList">
      {Array.isArray(userList) &&
        userList.map((item) => (
          <div key={item.uuid} className="listItem" onClick={onClickUser(item)}>
            <div>{`${item.firstname} ${item.lastname} (${item.mail}) (Admin: ${item.isAdmin.toString()})`}</div>
          </div>
        ))}
    </div>
  )
}

export default List
