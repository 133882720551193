import { useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../fetch'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import './ModalContent.scss'
import Textarea from '../../UI/Textarea/Textarea'
import Input from '../../UI/Input/Input'
import toast, { TYPE } from '../../../toast'

const End = ({ close }) => {
  const [tasks, setTasks] = useState('')
  const [breakTimeInMinutes, setBreakTimeInMinutes] = useState(0)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)

  const end = async () => {
    const { status } = await fetch(METHOD.PUT, '/time', { token, tasks, breakTimeInMinutes })
    if (status) {
      close()
    } else {
      toast(TYPE.ERROR, translation['end.error'])
    }
  }

  return (
    <div id="modalContent">
      <Textarea value={tasks} onChange={setTasks} placeholder={translation['end-modal.tasks']} />
      <Input type="customNumber" onChange={setBreakTimeInMinutes} value={breakTimeInMinutes} placeholder={translation['end-modal.breakTime']} symbol={translation['min.symbol']} />
      <div className="btnAction" onClick={end}>
        <div>{translation['button.end']}</div>
        <PlayArrowIcon />
      </div>
    </div>
  )
}

export default End
