import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../fetch'
import customToast, { TYPE } from '../../../toast'
import Layout from '../../Layout/Layout'
import { Headline, ListWrapper } from './EditHistory.Styled.js'
import Container from 'react-bootstrap/esm/Container'
import TimeEntry from './TimeEntry/TimeEntry'
import Dropdown from '../../UI/Dropdown/Dropdown'

const EditHistory = () => {
  const [list, setList] = useState(null)
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const [projects, setProjects] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)

  useEffect(() => {
    const fetchList = async () => {
      const { status, result } = await fetch(METHOD.GET, '/edit/history', { token, projectUUID: selectedProject.uuid })
      if (status && result) {
        setList(result)
      } else {
        customToast(TYPE.ERROR, "Couldn't fetch edit history")
      }
    }

    const fetchProjects = async () => {
      const { status, projects } = await fetch(METHOD.GET, '/admin/project/list', { token })
      if (status && projects) {
        setProjects(projects)
      } else {
        customToast(TYPE.ERROR, "Couldn't fetch projects")
      }
    }

    if (token && !projects) fetchProjects()

    if (token && !list && selectedProject) fetchList()
  }, [list, token, projects, selectedProject])

  const update = (key) => setSelectedProject(projects.find((p) => p.uuid === key))

  return (
    <Layout authRequired={'admin'}>
      {Array.isArray(projects) && (
        <Container>
          <Headline>Timeentry Edit History:</Headline>

          <Dropdown
            showEmptyOption={false}
            name={translation['project.select']}
            options={projects.map((p) => ({ key: p.uuid, value: p.name }))}
            onChange={update}
            value={selectedProject ? selectedProject.uuid : ''}
          />

          <ListWrapper>
            {list?.map((entry) => (
              <TimeEntry entry={entry} key={entry.uuid} />
            ))}
            {list?.length === 0 && <div>No entries found</div>}
          </ListWrapper>
        </Container>
      )}
    </Layout>
  )
}

export default EditHistory
