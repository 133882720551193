import Layout from '../../Layout/Layout'
import List from './List/List'

const ProjectManagement = () => {
  return (
    <Layout authRequired="admin">
      <div id="project-management">
        <List />
      </div>
    </Layout>
  )
}

export default ProjectManagement
