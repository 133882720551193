import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../fetch'
import toast, { TYPE } from '../../../toast'
import { getPrettyDateTime } from '../../../utility'
import usePrevious from '../../Helper/usePrevious'
import EditModal from './EditModal/EditModal'
import './List.scss'
import { Edit, EditWrapper } from './List.Styled.js'

const List = ({ date, setIsStartEnable }) => {
  const token = useSelector((state) => state.token)
  const translation = useSelector((state) => state.translation)
  const [timelist, settimelist] = useState(null)
  const user = useSelector((state) => state.user)
  const [open, setOpen] = useState(false)
  const [currentEntry, setCurrentEntry] = useState(null)

  const prevDate = usePrevious(date)

  useEffect(() => {
    const getEntries = async () => {
      const { status, entries } = await fetch(METHOD.GET, '/time', { token, date })
      if (status && Array.isArray(entries)) {
        const projectCurrentUser = entries.filter((res) => res.userUUID === user.uuid)
        const includesRunningProject = projectCurrentUser.reduce((acc, el) => (acc ? true : !el.endTime), false)
        setIsStartEnable(!includesRunningProject)
        settimelist(entries)
      } else {
        toast(TYPE.ERROR, translation['time.entries.loading.error'])
      }
    }

    if (token && (!timelist || prevDate !== date)) getEntries()
  }, [token, timelist, date, prevDate, user, setIsStartEnable, translation])

  const getSaldoString = (item) => {
    const startInMillis = new Date(item.startTime).getTime()
    const endInMillis = new Date(item.endTime).getTime()
    const breakTimeInMillis = item.breakTimeInMinutes * 60000
    const saldoDate = new Date(endInMillis - startInMillis - breakTimeInMillis)
    return `${saldoDate.getUTCHours()} ${translation['time.entry.saldo.hours.label']} ${saldoDate.getMinutes()} ${translation['time.entry.saldo.minutes.label']}`
  }

  if (!timelist) return <></>

  return (
    <div id="timelist">
      <div className="headline">{translation['time.entries.headline']}</div>
      <div className="list">
        {timelist.map((item) => (
          <div key={item.uuid} className="listItem">
            <div className="main">
              <div>
                {translation['time.entry.person.label']}
                {`${item.user.firstname} ${item.user.lastname}`}
              </div>
              <div>
                {translation['time.entry.project.label']}
                {item.project.name}
              </div>
            </div>
            <div className="meta">
              <div>
                {translation['time.entry.start.label']}
                {item.startTime && getPrettyDateTime(new Date(item.startTime))}
              </div>
              <div>
                {translation['time.entry.end.label']}
                {item.endTime ? getPrettyDateTime(new Date(item.endTime)) : translation['time.until-now']}
              </div>
              {item.endTime && (
                <div>
                  {translation['time.entry.break-time.label']}
                  {`${item.breakTimeInMinutes} ${translation['time.entry.break-time.minutes.label']}`}
                </div>
              )}
              {item.startTime && item.endTime && !isNaN(item.breakTimeInMinutes) && (
                <div>
                  {translation['time.entry.saldo.label']}
                  {item.endTime && getSaldoString(item)}
                </div>
              )}
            </div>
            {item.endTime && (
              <div className="tasks-wrapper">
                {translation['time.entry.tasks.label']}
                <textarea disabled={true} value={item.tasks}></textarea>
              </div>
            )}
            {item.endTime && user && user.isAdmin && (
              <EditWrapper>
                <Edit
                  onClick={() => {
                    setOpen(true)
                    setCurrentEntry(item)
                  }}
                >
                  Edit
                </Edit>
              </EditWrapper>
            )}
          </div>
        ))}
      </div>
      {timelist.length === 0 && <div className="no-result">{translation['time.entries.no-results']}</div>}
      <EditModal currentEntry={currentEntry} setCurrentEntry={setCurrentEntry} open={open} setOpen={setOpen} />
    </div>
  )
}

export default List
