import de, { langKey } from './translationFiles/de'

const defaultState = {
  token: null,
  user: null,
  translation: de,
  currentLang: langKey,
}

export default defaultState
