import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import { useSelector } from 'react-redux'
import fetch, { fileDownload, METHOD } from '../../../fetch'
import toast, { TYPE } from '../../../toast'
import { getNormalizedDate, triggerClientSideDownload, getPrettyDate, getPrettyDateTime } from '../../../utility'
import Layout from '../../Layout/Layout'
import Button from '../../UI/Button/Button'
import CB from '../../UI/Checkbox/Checkbox'
import Dropdown from '../../UI/Dropdown/Dropdown'
import Input from '../../UI/Input/Input'
import './Export.scss'

const Export = () => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)
  const [projectList, setProjectList] = useState(null)
  const [userList, setUserList] = useState(null)
  const [history, setHistory] = useState(null)
  const [filters, setFilters] = useState({
    startDateChecked: false,
    startDate: new Date(),
    endDateChecked: false,
    endDate: new Date(),
    selectedProject: '',
    selectedUser: '',
  })

  useEffect(() => {
    const getProjects = async () => {
      const { status, projects } = await fetch(METHOD.GET, '/admin/project/list', { token })
      if (status && Array.isArray(projects)) {
        setProjectList(projects)
      } else {
        toast(TYPE.ERROR, translation['admin.export.project-list.error'])
      }
    }

    const getUsers = async () => {
      const { status, users } = await fetch(METHOD.GET, '/admin/user/list', { token })
      if (status && Array.isArray(users)) {
        setUserList(users)
      } else {
        toast(TYPE.ERROR, translation['admin.export.user-list.error'])
      }
    }

    const getHistory = async () => {
      const { status, history } = await fetch(METHOD.GET, '/admin/export/history', { token })
      if (status && Array.isArray(history)) {
        setHistory(history)
      } else {
        toast(TYPE.ERROR, translation['admin.export.history.error'])
      }
    }

    if (token && !projectList) getProjects()
    if (token && !userList) getUsers()
    if (token && !history) getHistory()
  }, [projectList, token, userList, translation, history])

  const onClickExport = async (reexportEntry) => {
    console.log(reexportEntry)
    const response = await fileDownload('/admin/export', {
      token,
      filters: reexportEntry ? reexportEntry : filters,
    })
    if (response) {
      const fileName = `timetracking-export-${getNormalizedDate(new Date())}.pdf`
      triggerClientSideDownload(response, fileName)
    }
  }

  const update = (prop) => (value) => setFilters({ ...filters, [prop]: value })

  const getTimespan = (entry) => {
    const { startDateChecked, startDate, endDateChecked, endDate } = entry
    if (startDateChecked) {
      if (endDateChecked) {
        return `${getPrettyDate(new Date(startDate))} - ${getPrettyDate(new Date(endDate))}`
      } else {
        return `${getPrettyDate(new Date(startDate))} - unlimited`
      }
    } else if (endDateChecked) {
      return `unlimited - ${getPrettyDate(new Date(endDate))}`
    }
    return 'unlimited - unlimited'
  }

  const exportAgain = (entry) => () => onClickExport(entry)

  return (
    <Layout authRequired={'admin'}>
      {projectList && userList && history && (
        <Container>
          <div id="export">
            <div className="headline">{translation['admin.export.headline']}</div>
            <div className="timespan">
              <div className="item">
                <CB text={translation['admin.export.startDate.label']} checked={filters.startDateChecked} onChange={update('startDateChecked')} />
                <Input
                  className="criteriaInput"
                  disabled={!filters.startDateChecked}
                  type="date"
                  value={getNormalizedDate(filters.startDate)}
                  onChange={(value) => update('startDate')(new Date(value))}
                />
              </div>
              <div className="item">
                <CB text={translation['admin.export.endDate.label']} checked={filters.endDateChecked} onChange={update('endDateChecked')} />
                <Input className="criteriaInput" disabled={!filters.endDateChecked} type="date" value={getNormalizedDate(filters.endDate)} onChange={(value) => update('endDate')(new Date(value))} />
              </div>
            </div>
            <div className="project-selection">
              <Dropdown
                showEmptyOption
                name={translation['project.select']}
                options={projectList.map((p) => ({ key: p.uuid, value: p.name }))}
                onChange={update('selectedProject')}
                value={filters.selectedProject}
              />
            </div>
            <div className="user-selection">
              <Dropdown
                showEmptyOption
                name={translation['user.select']}
                options={userList.map((p) => ({ key: p.uuid, value: `${p.firstname} ${p.lastname}` }))}
                onChange={update('selectedUser')}
                value={filters.selectedUser}
              />
            </div>
            <Button className="start-button" text={translation['admin.export.download']} onClick={() => onClickExport()} />
            <div className="history">
              <div className="headline">{translation['admin.export.history.headline']}</div>
              <div className="list">
                {history.map((entry) => {
                  return (
                    <div className="entry" key={entry.uuid}>
                      <div className="createdOn">
                        {translation['admin.export.history.createdOn']}
                        {getPrettyDateTime(new Date(entry.createdOn))}
                      </div>
                      <div className="timespan">
                        {translation['admin.export.history.timespan']}
                        {getTimespan(entry)}
                      </div>
                      {entry.user && <div className="user">{`${translation['admin.export.history.user']} ${entry.user.firstname} ${entry.user.lastname} (${entry.user.mail})`}</div>}
                      {entry.project && <div className="project">{`${translation['admin.export.history.project']} ${entry.project.name}`}</div>}
                      <div className="re-export-wrapper">
                        <Button fullWidth={false} className="reexport-button" text={translation['admin.export.history.re-export']} onClick={exportAgain(entry)} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Container>
      )}
    </Layout>
  )
}

export default Export
