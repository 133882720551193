import Modal from '../../../UI/Modal/Modal'
import { getDateTimeInputFormat } from '../../../../utility'
import { Input, Textarea, Wrapper, ButtonWrapper, Button } from './EditModal.Styled.js'
import fetch, { METHOD } from '../../../../fetch'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const EditModal = ({ setCurrentEntry, currentEntry, open, setOpen }) => {
  const token = useSelector((state) => state.token)

  const [propsChanged, setPropsChanged] = useState([])

  const updateProp = (prop) => (e) => {
    setCurrentEntry({ ...currentEntry, [prop]: e.target.value })
    setPropsChanged([...propsChanged, prop])
  }

  const save = async () => {
    const result = await fetch(METHOD.POST, '/edit', { entry: currentEntry, token, propsChanged })
    if (result.status) {
      close()
    }
  }

  const close = () => {
    setOpen(false)
    setCurrentEntry(null)
  }

  return (
    <Modal
      title={'Edit Entry'}
      close={close}
      children={
        <Wrapper>
          <Input type="datetime-local" value={getDateTimeInputFormat(new Date(currentEntry?.startTime))} onChange={updateProp('startTime')} />
          <Input type="datetime-local" value={getDateTimeInputFormat(new Date(currentEntry?.endTime))} onChange={updateProp('endTime')} />
          <Input type="number" value={currentEntry?.breakTimeInMinutes} onChange={updateProp('breakTimeInMinutes')} />
          <Textarea value={currentEntry?.tasks} onChange={updateProp('tasks')} />
          <ButtonWrapper>
            <Button onClick={close}>Abort</Button>
            <Button onClick={save}>Save</Button>
          </ButtonWrapper>
        </Wrapper>
      }
      open={open}
    />
  )
}

export default EditModal
