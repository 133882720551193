const translation = {
  'header.purchase-portal-label': 'Ankaufsportal',
  imprint: 'Impressum',
  'data-protection': 'Datenschutz',

  'dropdown.empty-selection': 'Choose Option',

  'login.wrong-credentials': 'credentials incorrect!',
  'login.missing-inputs': 'One or more fields are empty!',
  'login.headline': 'Login',
  'login.mail': 'E-Mail',
  'login.password': 'Password',
  'login.button': 'Login',

  'button.start': 'Start',
  'button.end': 'End',

  'min.symbol': ' min',

  'user.select': 'Select User',

  'project.select': 'Select Project',
  'end-modal.tasks': 'Enter Tasks',
  'end-modal.breakTime': 'Break Time',
  'end-modal.error': 'Error while saving endTime',
  'custom-mui-suggestion.selected.title': 'Selected:',
  'custom-mui-suggestion.options.title': 'Suggestions:',
  'time.until-now': 'until Now',
  'time.entries.loading.error': 'Failed loading time entries for date.',
  'time.entries.headline': 'Entries:',
  'time.entries.no-results': 'No entries found for this date.',
  'time.tracking.headline': 'Track your times:',
  'time.entry.headline.start': 'Start New Entry',
  'time.entry.headline.end': 'Start Current Entry',
  'time.entry.start.label': 'Start: ',
  'time.entry.end.label': 'End: ',
  'time.entry.break-time.label': 'Break Time: ',
  'time.entry.break-time.minutes.label': ' Minutes',
  'time.entry.saldo.label': 'Saldo: ',
  'time.entry.saldo.hours.label': ' Hours',
  'time.entry.saldo.minutes.label': ' Minutes',
  'time.entry.person.label': 'Person: ',
  'time.entry.project.label': 'Project: ',
  'time.entry.tasks.label': 'Tasks:',

  'admin.user-management.list.headline': 'Users:',

  'admin.project.cta': 'Project Management',
  'admin.project-management.list.headline': 'Projects:',
  'admin.project-management.list.create.cta': 'Create New Project',
  'admin.project-management.list.error': 'Failed loading projects.',
  'admin.project-management.list.no-results': 'No existing projects found.',
  'admin.project-management.create.headline': 'Create New Project:',
  'admin.project-management.create.error': 'New project could not be created.',
  'admin.project-management.create.name.placeholder': 'Name',
  'admin.project-management.create.user-suggestion.error': 'Failed searching for users.',
  'admin.project-menagement.create.submit-cta': 'Create',
  'admin.project-management.create.submit-cta': 'Create',
  'admin.project-management.members.users-list.error': 'Failed fetching all users.',
  'admin.project-management.members.project-get.error': 'Failed fetching project.',
  'admin.project-management.members.selection.label': 'Select Members',
  'admin.project-management.members-amount.label': 'Members: ',
  'admin.project-management.update.name.placeholder': 'Name',
  'admin.project-management.update.error': 'Failed updating project.',
  'admin.project-management.update.save-cta': 'Save',
  'admin.project-management.upsert.headline': 'Project:',
  'admin.project-management.upsert.name.placeholder': 'Name',
  'admin.project-management.upsert.error': 'New project could not be saved.',
  'admin.project-management.upsert.submit-cta': 'Save',
  'admin.project-management.upsert.mark-as-complete-cta': 'Mark as Complete',
  'admin.project-management.completed.label': 'COMPLETED',
  'admin.project-management.delete.error': 'Failed deleting project.',
  'admin.project-management.delete.cta': 'Remove',

  'admin.user.cta': 'User Management',
  'admin.user.create-user': 'Create New User',
  'admin.user.update-user': 'Update User',
  'admin.user.delete-user': 'Delete User',
  'admin.user.firstname': 'Firstname',
  'admin.user.lastname': 'Lastname',
  'admin.user.mail': 'E-Mail',
  'admin.user.password': 'Password',
  'admin.user.isAdmin': 'isAdmin',

  'admin.history.cta': 'Edit History',

  'admin.export.cta': 'Export',
  'admin.export.headline': 'Export:',
  'admin.export.download': 'Generate Export PDF',
  'admin.export.startDate.label': 'Start Date',
  'admin.export.endDate.label': 'End Date',
  'admin.export.project-list.error': 'Could not load project list.',
  'admin.export.user-list.error': 'Could not load user list.',
  'admin.export.history.headline': 'History:',
  'admin.export.history.error': 'Could not load history.',
  'admin.export.history.timespan': 'Timespan: ',
  'admin.export.history.user': 'Employee: ',
  'admin.export.history.project': 'Project: ',
  'admin.export.history.createdOn': 'Created On: ',
  'admin.export.history.re-export': 'Export Again',
}

export const langKey = 'DE'

export default translation
