import { useSelector } from 'react-redux'
import fetch, { METHOD } from '../../../../../fetch'
import toast, { TYPE } from '../../../../../toast'
import Checkbox from '../../../../UI/Checkbox/Checkbox'
import { getUUID } from '../../../../../utility'
import './Members.scss'

const { useState, useEffect } = require('react')

const Members = ({ projectUUID, selectedCallback, members, disabled }) => {
  const token = useSelector((s) => s.token)
  const translation = useSelector((s) => s.translation)
  const [users, setUsers] = useState(null)

  useEffect(() => {
    const fetchUsers = async () => {
      const { status, users } = await fetch(METHOD.GET, '/admin/user/list', { token })
      if (status && Array.isArray(users)) {
        setUsers(users)
      } else {
        toast(TYPE.ERROR, translation['admin.project-management.members.users-list.error'])
      }
    }
    if (token && !users) {
      fetchUsers()
    }
  }, [token, users, translation, projectUUID, members])

  const toggleMember = (memberUUID) => () => {
    const newMemberList = members.includes(memberUUID) ? [...members.filter((m) => m !== memberUUID)] : [...members, memberUUID]
    selectedCallback(newMemberList)
  }

  if (!users || !members || !selectedCallback) return false

  return (
    <div className="member-list">
      <div className="user-list">
        {users.map((u) => (
          <div className="user" key={getUUID()}>
            <Checkbox disabled={disabled} text={`${u.firstname} ${u.lastname}`} checked={members.includes(u.uuid)} onChange={toggleMember(u.uuid)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Members
