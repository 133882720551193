import styled from 'styled-components'

export const Wrapper = styled.div``

export const Input = styled.input`
  width: 100%;
  margin-top: 10px;
`

export const Textarea = styled.textarea`
  margin-top: 10px;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

export const Button = styled.div`
  padding: 10px;
  text-align: center;
  background-color: black;
  border-radius: 5px;
  color: white;
  width: 30%;
  cursor: pointer;
`
