import { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import Input from '../UI/Input/Input'
import './Home.scss'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { getNormalizedDate } from '../../utility'
import { useSelector } from 'react-redux'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import List from './List/List'
import Modal from '../UI/Modal/Modal'
import Start from './ModalContent/Start'
import End from './ModalContent/End'
import Container from 'react-bootstrap/esm/Container'

const Home = () => {
  const [date, setDate] = useState(new Date())
  const [modalContentType, setModalContentType] = useState(null)
  const translation = useSelector((state) => state.translation)
  const [isStartEnable, setIsStartEnable] = useState(true)
  const [buttonsEnabled, setButtonsEnabled] = useState(true)

  useEffect(() => {
    setButtonsEnabled(date.toDateString() === new Date().toDateString())
  }, [buttonsEnabled, date])

  const changeDay = (offset) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + offset)
    setDate(newDate)
  }

  const onChangeDate = (value) => setDate(new Date(value))

  const handleClose = () => {
    setModalContentType(null)
    setDate(new Date())
  }

  const onClickActionButton = (value) => () => {
    if (!isStartEnable && value === 'start') return
    setModalContentType(value)
  }

  return (
    <Layout authRequired>
      <div id="home">
        <Container>
          <div className="headline">{translation['time.tracking.headline']}</div>
          <div className="navigator">
            <div className="timeWrapper">
              <ArrowBackIosIcon onClick={() => changeDay(-1)} />
              <Input type="date" value={getNormalizedDate(date)} onChange={onChangeDate} />
              <ArrowForwardIosIcon onClick={() => changeDay(1)} />
            </div>
            {buttonsEnabled && (
              <div className="btnWrapper">
                {isStartEnable ? (
                  <div className="btnAction" onClick={onClickActionButton('start')}>
                    <div>{translation['button.start']}</div>
                    <PlayArrowIcon />
                  </div>
                ) : (
                  <div className="btnAction" onClick={onClickActionButton('end')}>
                    <div>{translation['button.end']}</div>
                    <StopIcon />
                  </div>
                )}
              </div>
            )}
          </div>
          <List date={date} setIsStartEnable={setIsStartEnable} />
          <Modal open={!!modalContentType} close={handleClose} title={translation[`time.entry.headline.${modalContentType}`]}>
            {modalContentType === 'start' && <Start close={handleClose} />}
            {modalContentType === 'end' && <End close={handleClose} />}
          </Modal>
        </Container>
      </div>
    </Layout>
  )
}

export default Home
