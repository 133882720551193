import { useState } from 'react'
import { useSelector } from 'react-redux'
import './Item.scss'
import Button from '../../../../UI/Button/Button'
import toast, { TYPE } from '../../../../../toast'
import fetch, { METHOD } from '../../../../../fetch'
import Upsert from '../../Upsert/Upsert'

const Item = ({ project, updateCallback, removeCallback }) => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.token)
  const [show, setShow] = useState(false)

  const remove = async () => {
    const { status } = await fetch(METHOD.DELETE, '/admin/project', { token, projectUUID: project.uuid })
    if (status) {
      removeCallback(project.uuid)
    } else {
      toast(TYPE.ERROR, translation['admin.project-management.delete.error'])
    }
  }

  return (
    <div className="project-item">
      <div className="item name" onClick={() => setShow(!show)}>
        {project.name}
      </div>
      <div className="item">
        {translation['admin.project-management.members-amount.label']}
        {project.members.length}
      </div>
      <div className="item">
        {project.completed ? translation['admin.project-management.completed.label'] : <Button onClick={remove} text={translation['admin.project-management.delete.cta']} fullWidth={false} />}
      </div>
      <Upsert show={show} close={() => setShow(false)} project={project} projectCallback={updateCallback} />
    </div>
  )
}

export default Item
