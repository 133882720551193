import styled from 'styled-components'

export const Edit = styled.div`
  background-color: white;
  text-align: center;
  padding: 10px 50px;
  border-radius: 5px;
  color: black !important;
  width: 30%;
  cursor: pointer;
`

export const EditWrapper = styled.div`
  width: 100%;
  text-align: right;
`
