import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUUID } from '../../../../utility'
import './List.scss'
import Item from './Item/Item'
import fetch, { METHOD } from '../../../../fetch'
import toast, { TYPE } from '../../../../toast'
import Button from '../../../UI/Button/Button'
import Container from 'react-bootstrap/esm/Container'
import Upsert from '../Upsert/Upsert'

const List = () => {
  const translation = useSelector((state) => state.translation)
  const token = useSelector((state) => state.token)
  const [projects, setProjects] = useState(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const listProjects = async () => {
      const { status, projects } = await fetch(METHOD.GET, '/admin/project/list', { token })
      if (status && Array.isArray(projects)) {
        setProjects(projects)
      } else {
        toast(TYPE.ERROR, translation['admin.project-management.list.error'])
      }
    }

    if (token && !projects) {
      listProjects()
    }
  })

  const removeItemFromList = (projectUUID) => setProjects([...projects.filter((p) => p.uuid !== projectUUID)])

  const updateProjectInList = (project) => setProjects([...projects.map((p) => (p.uuid === project.uuid ? project : p))])

  const addNewProjectToList = (project) => setProjects([project, ...projects])

  if (!projects) return <></>
  return (
    <div className="project-list">
      <Container>
        <div className="headline">{translation['admin.project-management.list.headline']}</div>
        <div className="create-wrapper">
          <Button text={translation['admin.project-management.list.create.cta']} onClick={() => setShow(true)} />
          <Upsert show={show} close={() => setShow(false)} projectCallback={addNewProjectToList} />
        </div>
        {projects.map((p) => (
          <Item project={p} key={getUUID()} removeCallback={removeItemFromList} updateCallback={updateProjectInList} />
        ))}
        {projects.length === 0 && <div className="no-projects">{translation['admin.project-management.list.no-results']}</div>}
      </Container>
    </div>
  )
}

export default List
