import * as types from '../type/types'
import * as user from './user'
import * as responsiveState from './responsiveState'
import * as translation from './translation'
import defaultState from './defaultState'

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_USER_TOKEN:
      return user.setToken(state, action)
    case types.SET_USER:
      return user.set(state, action)
    case types.SET_RESPONSIVE_STATE:
      return responsiveState.set(state, action)
    case types.SET_TRANSLATION:
      return translation.set(state, action)
    default:
      return state
  }
}

export default reducer
